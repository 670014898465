/**
 * Created by zeitler on 07-06-2016.
 */
addLoadEvent(boot);

var menu;
var subMenu;
var EXPAND_CLASS = "expand";
var MENU_SIZE = 300;

function boot(){
    menu = new Menu("menu_icon", "menu", "content",  "overlay");
}


class Menu{
    get menuIconId(){
        return this._menuIconId;
    }

    set menuIconId(val){
        this._menuIconId = val;
    }

    _getMenuIconObj(){
        var icon = document.getElementById(this.menuIconId);
        this._menuIcon = icon;
        if (icon == null)
            console.log(this.getMenuIcon + " not found");
    }

    get menuId(){return this._menuId;}set menuId(value){this._menuId = value;}
    _getMenuObj(){
        this._menu = document.getElementById(this.menuId);
        if (this._menu == null)
            console.log(this.menuId + " not found");
    }

    get overlayId(){return this._overlayId;}set overlayId(value){this._overlayId = value;}

    _getOverlayObj(){
        this._overlay = document.getElementById(this.overlayId);
        if (this._overlay == null)
            console.log(this._overlayId + " not found");
    }

    get pageContentId(){return this._pageContentId}set pageContentId(value){this._pageContentId = value}
    _getpageContentObj(){
        this._pageContent = document.getElementById(this.pageContentId)
        if (this._pageContent == null)
            console.log(this._pageContentId + " not found")
    }

    constructor(menuIconId, menuId, contentId, overlayId){
        this._menuIconId = menuIconId;
        this._menuId = menuId;
        this._pageContentId = contentId;
        this._overlayId = overlayId;
        this.getElements();
        this.refresh();
    }

    refresh(){
        this.addMenuTransition();
    }

    getElements(){
        this._getMenuIconObj();
        this._getMenuObj();
        this._getpageContentObj();
        this._getOverlayObj();
    }

    addMenuTransition(){
        if (this._menuIcon != null) {
            var myMenu = this._menu;
            var myContent = this._pageContent;
            var myOverlay = this._overlay;
            var that = this;
            this._menuIcon.onclick = (function () {
                this.classList.toggle(EXPAND_CLASS);
                myMenu.classList.toggle(EXPAND_CLASS);
                myContent.classList.toggle(EXPAND_CLASS);
                myOverlay.classList.toggle(EXPAND_CLASS);
            })
        }
    }
}